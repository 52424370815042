import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
// components
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { ROLES } from 'src/utils/Constant';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  ic_rupee_circle: icon('ic_rupee_circle'),
  golfCart: icon('ic_golfcart'),
  sos: icon('ic_sos'),
  qr: icon('ic_qr_code'),
  map: icon('ic_map'),
  car: icon('ic_car'),
  hotel: icon('ic_hotel'),
  delegates: icon('ic_multiple-users'),
  exhibitors: icon('presentation-line'),
  inquires: icon('ic_info-square'),
  live_streaming: icon('ic_live_tv'),
  reports: icon('ic_reports'),
  ladies: icon('ic_ladies'),
  delegateGuest: icon('ic_delegete-guest'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  // const user = JSON.parse(localStorage.getItem('user'));

  // const { t } = useLocales();

  const data = useMemo(
    () =>
      // if (menuItems) {
      [
        // IRC
        // menuItems is localStorage data provided by loginApi response All paths icons and title manage from backend data
        // ----------------------------------------------------------------------
        {
          // subheader: menuItems.subheader,
          // items: menuItems.items.map((row) => ({
          //   title: row.title,
          //   path: row.path,
          //   icon: icon(row.icon),
          // })),
          items: [
            {
              title: 'Dashboard',
              path: paths.irc.root,
              icon: ICONS.dashboard,
              roles: [
                ROLES.admin,
                ROLES.superadmin,
                ROLES.finance,
                ROLES.tour,
                ROLES.accommodation,
                ROLES.transport,
                ROLES.exhibitor,
                ROLES.ladiescommittee,
                ROLES.medical,
                ROLES?.revenue,
              ],
            },
            {
              title: 'Delegates',
              path: paths.irc.delegates.list,
              icon: ICONS.delegates,
              roles: [
                ROLES.admin,
                ROLES.superadmin,
                ROLES.accommodation,
                ROLES.finance,
                ROLES.tour,
                ROLES.transport,
              ],
            },
            {
              title: 'Accompanying Guest',
              path: paths.irc.delegates.guestUser,
              icon: ICONS.delegateGuest,
              roles: [
                ROLES.admin,
                ROLES.superadmin,
                ROLES.accommodation,
                ROLES.finance,
                ROLES.tour,
                ROLES.transport,
              ],
            },
            {
              title: 'Accommodation',
              path: paths.irc.accommodation.root,
              icon: ICONS.hotel,

              children: [
                {
                  title: 'Guests',
                  path: paths.irc.accommodation.guest_users,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.accommodation],
                },
                {
                  title: 'Hotels',
                  path: paths.irc.accommodation.hotel,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.accommodation],
                },
              ],
            },
            {
              title: 'Vehicles',
              path: paths.irc.vehicles.root,
              icon: ICONS.car,

              children: [
                {
                  title: 'Vehicles',
                  path: paths.irc.vehicles.details,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.transport],
                },
                {
                  title: 'Passengers ',
                  path: paths.irc.vehicles.passengers,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.transport],
                },
                {
                  title: 'On Demand',
                  path: paths.irc.vehicles.on_demand,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.transport],
                },
              ],
            },
            // {
            //   title: 'PR',
            //   path: paths.irc.pr,
            //   icon: ICONS.user,
            //   roles: [ROLES.admin, ROLES.superadmin],
            // },
            {
              title: 'Tour',
              path: paths.irc.tour_details,
              icon: ICONS.map,
              roles: [ROLES.admin, ROLES.superadmin, ROLES.tour],
            },
            {
              title: 'Exhibitors',
              path: paths.irc.exhibitors,
              icon: ICONS.exhibitors,
              roles: [ROLES.admin, ROLES.superadmin, ROLES.exhibitor],
            },
            {
              title: 'Finance',
              path: paths.irc.finance.root,
              icon: ICONS.ic_rupee_circle,
              // roles: [ROLES.admin , ROLES.superadmin,, ROLES.finance, ROLES.accommodation],
              children: [
                {
                  title: 'Accommodation',
                  path: paths.irc.finance.accommodation_fees,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.accommodation, ROLES?.revenue],
                },
                {
                  title: 'Tour',
                  path: paths.irc.finance.tour_fees,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.tour, ROLES?.revenue],
                },
                // {
                //   title: 'Exhibitors',
                //   path: paths.irc.finance.exhibitor_fees,
                //   roles: [ROLES.admin , ROLES.superadmin,, ROLES.exhibitor, ROLES?.revenue],
                // },
              ],
            },
            {
              title: 'QR History',
              path: paths.irc.qr_history,
              icon: ICONS.qr,
              roles: [ROLES.admin, ROLES.superadmin],
            },
            // {
            //   title: 'Golf Cart',
            //   path: paths.irc.golf_cart,
            //   icon: ICONS.golfCart,
            // },
            {
              title: 'SOS',
              path: paths.irc.sos,
              icon: ICONS.sos,
              roles: [ROLES.admin, ROLES.superadmin, ROLES.medical],
            },
            {
              title: 'Inquires',
              path: paths.irc.inquires.root,
              icon: ICONS.inquires,
              // roles: [ROLES.admin , ROLES.superadmin,],
              children: [
                {
                  title: 'General',
                  path: paths.irc.inquires.general,
                  roles: [ROLES.admin, ROLES.superadmin],
                },
                // {
                //   title: 'Sponsorship',
                //   path: paths.irc.inquires.sponsorship,
                //   roles: [ROLES.admin, ROLES.superadmin],
                // },

                // {
                //   title: 'Exhibitors',
                //   path: paths.irc.inquires.exhibitor,
                //   roles: [ROLES.admin , ROLES.superadmin,, ROLES.exhibitor],
                // },
              ],
            },
            {
              title: 'Live Streaming',
              path: paths.irc.live_streaming,
              icon: ICONS.live_streaming,
              roles: [ROLES.admin, ROLES.superadmin],
            },
            // {
            //   title: 'Reports',
            //   path: paths.irc.reports,
            //   icon: ICONS.reports,
            //   roles: [ROLES.admin , ROLES.superadmin,],
            // },

            {
              title: 'Reports',
              path: paths.irc.reports.root,
              icon: ICONS.reports,
              // roles: [ROLES.admin , ROLES.superadmin,, ROLES.accommodation],
              children: [
                {
                  title: 'Accommodation Master',
                  path: paths.irc.reports.accommodation,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.accommodation],
                },

                {
                  title: 'Accommodation Summary',
                  path: paths.irc.reports.accommodationSummary,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.accommodation],
                },
                {
                  title: 'Tour Master',
                  path: paths.irc.reports.tour,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.tour],
                },
                {
                  title: 'Tour Summary',
                  path: paths.irc.reports.tourSummary,
                  roles: [ROLES.admin, ROLES.superadmin, ROLES.tour],
                },
              ],
            },

            {
              title: 'Ladies Committee',
              path: paths.irc.ladiesCommittee,
              icon: ICONS.ladies,
              roles: [ROLES.admin, ROLES.superadmin, ROLES.ladiescommittee],
            },

            {
              title: 'Designation List',
              path: paths.irc.delegateDesignationList,
              icon: ICONS.user,
              roles: [ROLES.superadmin],
            },

            // {
            //   title: 'Reports',
            //   path: paths.irc.reports,
            //   icon: ICONS.car,
            // },
          ],
        },
      ],

    []
  );

  // console.log('data: ', data);
  // const filterRoutes = data[0]?.items.filter((row) => row.roles.includes(user?.role));
  // console.log('filterRoutes---->>>', filterRoutes);

  return data;
}
